<template>
	<div class="uaqiContainer">
		<div class="box radiation">
			Радіація
		</div>
		<div class="box pm">
			Мікропил
		</div>
		<div class="box other-gases">
			Інші гази
		</div>
		<div class="box main-gases">
			Основні гази
		</div>
		<div class="center-box-bg"></div>
		<div class="center-box">
			<div class="levelText">
				{{ levelToText[uaqi.main] }}
			</div>
			<small>рівень</small>
		</div>
	</div>
</template>

<script setup>
import { defineProps } from 'vue'

import { uaqiColors } from '@/modules/UAQI.js'

defineProps({
	uaqi: Object
})

const levelToText = [ "-", "I", "II", "III", "IV", "V", "VI", "VII" ]
</script>

<style scoped>
.uaqiContainer {
	display: grid;
	grid-template-areas:
		"radiation pm"
		"other-gases main-gases";
	gap: 7px;
	grid-template-columns: 49% 49%;
	justify-content: space-around;
	position: relative;

	color: white;

	user-select: none;
}

.levelText {
	font-size: xx-large; 
	font-weight: bolder; 
	font-family: 'Montserrat', sans-serif;
}

.box {
	display: flex;
	box-sizing: border-box;

	border-radius: 10px;
	padding: 10px;
	height: 60px;

	transition: all 0.5s ease;
}

.center-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	

	padding: 20px;
	border-radius: 50%;

	/* box-shadow: 0px 1px 20px 5px #00000020; */
	background-color: v-bind(uaqiColors[uaqi.main] + "80");
	border: 1px solid v-bind(uaqiColors[uaqi.main]);

	/* border: 6px solid #101010; */
	
	font-size: medium;
	text-align: center;
	
	width: 50px;
	height: 50px;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.center-box-bg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
	border-radius: 50%;

	background-color: #101010;

	border: 5px solid #101010;
	
	width: 95px;
	height: 95px;
}

/* .center-box::backdrop */

.pm {
	grid-area: pm;
	display: flex;
	justify-content: right;

	background-color: v-bind(uaqiColors[uaqi.pm] + "80");
	border: 1px solid v-bind(uaqiColors[uaqi.pm]);
}

.radiation {
	grid-area: radiation;

	background-color: v-bind(uaqiColors[uaqi.radiation] + "80");
	border: 1px solid v-bind(uaqiColors[uaqi.radiation]);
}

.main-gases {
	grid-area: main-gases;
	display: flex;
	justify-content: right;
	align-items: end;

	background-color: v-bind(uaqiColors[uaqi.mainGases] + "80");
	border: 1px solid v-bind(uaqiColors[uaqi.mainGases]);
}

.other-gases {
	grid-area: other-gases;
	display: flex;
	align-items: end;

	background-color: v-bind(uaqiColors[uaqi.otherGases] + "80");
	border: 1px solid v-bind(uaqiColors[uaqi.otherGases]);
}
</style>
<script>
export default { name: 'PollutionMap' }
</script>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, defineExpose } from 'vue'

import { CustomMarker, GoogleMap } from 'vue3-google-map'
import { darkStyle } from '@/modules/GoogleMapStyle.js'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import RegionFloatingBox from '@/components/RegionFloatingBox.vue'

import { StationsData } from '@/state/StationsData.js'
import { RegionsData } from '@/state/RegionsData.js'
import { SelectedStation } from '@/state/SelectedStation.js'
import { uaqiColors } from '@/modules/UAQI.js'
import { getStationIcon } from '@/modules/StationIcon.js'

// Default map zoom and center point
const defaultCenter = { lat: 50.449399, lng: 30.523335 }
const defaultMapZoom = 10

// Is map animation running
var isMapAnimation = false

// When true, regions is shown and markers is minimized
const regionsMode = ref(false)

// Reference to Google Map object
const mapRef = ref(null)

// Region under the mouse
const selectedRegion = ref(null)

// Show regions on map
async function showRegions(show)
{
	console.log("SHOW REGIONS")

	const data = await RegionsData.getUpdated()

	mapRef.value.map.data.setStyle(function (feature) {
		// Get region from data
		const region = data.find(r => {
			return r.name === feature.getProperty("name")
		})
		
		// Get level and color
		const level = region ? region.level : 0
		const color = level != 0 ? uaqiColors[level] : "black"

		// Set level to properties
		feature.setProperty("level", level)

		return {
			fillColor: color,
			strokeWeight: 1,
			strokeColor: "#dadada",
			visible: show
		};
	});
}

// Show regions on zoomout state
watch(regionsMode, (regionsMode) => {
	console.log("Regions mode changed:  " + regionsMode)
	showRegions(regionsMode)
})

// Filtered stations to show on map
const stationsToShow = ref([])

function filterStationsToShow() {
	const all = StationsData.get()

	if (all == null) {
		stationsToShow.value = [ ]
	} else {
		stationsToShow.value = all.filter(e => isStationsInMapBounds(e))
	}
}

function isStationsInMapBounds(station) {
	let bounds = mapRef.value?.map?.getBounds()

	// Map not loaded
	if (bounds == null) return true

	// Expand to not clip
	bounds = expandBounds(bounds, 1.1)

	return bounds.contains(
		{ lat: station.latitude, lng: station.longitude }
	)
}

watch(StationsData, () => {
	filterStationsToShow()
})

// On component mounted
onMounted(() => {
	console.log("MAP component mounted")

	// Update data if needed
	if (StationsData.isUpdateNeeded()) StationsData.update()

	// Set in use to auto update stations
	StationsData.setInUse("map")
})

onBeforeUnmount(() => {
	StationsData.unsetInUse("map")
})

// Watch for "ready" then do something with the API or map instance
watch(() => mapRef.value?.ready, (ready) => {
	if (!ready) return

	console.log("GOOGLE MAP is ready")

	// Get map 
	const map = mapRef.value.map

	map.addListener("bounds_changed", () => { filterStationsToShow() })

	// Set markers to minimized and show regions
	map.addListener("zoom_changed", () => {
		regionsMode.value = (map.getZoom() < 8)
	})

	// When the user hovers, tempt them to click by outlining the regions
	map.data.addListener('mouseover', function (event) {
		// Call revertStyle() to remove all overrides. This will use the style 
		// rules defined in the function passed to setStyle()
		map.data.revertStyle()
		
		// Override stroke value
		map.data.overrideStyle(event.feature, { strokeWeight: 4 })

		selectedRegion.value = { 
			name: event.feature.getProperty("name"),
			level: event.feature.getProperty("level")
		}
	})

	map.data.addListener('mouseout', function (event) {
		map.data.revertStyle()

		// Hide region info box
		selectedRegion.value = null
	})

	// Region onclick
	map.data.addListener('click', function (event) {
		// Get bounds of region
		const bounds = new mapRef.value.api.LatLngBounds()
		event.feature.getGeometry().forEachLatLng(function (latlng) {
			bounds.extend(latlng)
		})

		// Zoom to bounds
		map.fitBounds(bounds)

		// Zoomin more if needed
		const listener = mapRef.value.api.event.addListenerOnce(map, 'bounds_changed', function () {
			if (map.getZoom() < 8) map.setZoom(9)
		})

		// Hide region info box
		selectedRegion.value = null
	})

	// Set last zoom to default
	var lastZoom = defaultMapZoom

	map.addListener("zoom_changed", () => {
		let currentZoom = map.getZoom()

		// Zoom to full Ukraine
		if (lastZoom > currentZoom && currentZoom < 8 && currentZoom > 6)
		{
			console.log("Zoomout detected")
			if (isMapAnimation == false) zoomToUkraine(mapRef)
		}

		lastZoom = currentZoom
	})

	// Get last map position
	const lastPositionJSON = localStorage.getItem('lastPosition')

	if (lastPositionJSON != null) 
	{ 
		const lastPosition = JSON.parse(lastPositionJSON) 
		map.panTo(lastPosition.location)
		map.setZoom(lastPosition.zoom)
	} 
	else 
	{
		// Trigger all of the event listeners
		map.panTo(defaultCenter)
		map.setZoom(defaultMapZoom)
	}

	map.addListener('tilesloaded', function (event) {
		const position = { location: map.getCenter(), zoom: map.getZoom() }
		localStorage.setItem('lastPosition', JSON.stringify(position))
	})

	// Load regions graphics
	setTimeout(() => {
		// As this is timeout function, it can be called when component 
		// unmounted already
		if (mapRef.value == null) return;

		// Load GeoJSON of ukraine regions
		console.log("Loading regions graphics")
		map.data.loadGeoJson("ukraine_edited_2_1.json")

		// Hide regions if not a regions mode
		showRegions(regionsMode.value)
	}, 500);
})

function zoomToUkraine() {
	let api = mapRef.value.api
	let map = mapRef.value.map

	console.log("Zoom to Ukraine")

    // Coordinates of Ukraine bounds
    const bounds = {
		// North offset to fit map under search bar
        north: ( 52.379528 + 0.5), // Northern latitude
        south: 44.390913, // South latitude
        east: 40.228727,  // Eastern longitude
        west: 22.128711   // Western longitude
    };

    // Create an object LatLngBounds for Ukraine's borders
    const ukraineBounds = new api.LatLngBounds(
        new api.LatLng(bounds.south, bounds.west), // Southwestern corner
        new api.LatLng(bounds.north, bounds.east)  // Northeast corner
    );

	// Disable user zoom control and set flag
	isMapAnimation = true
	map.setOptions({ gestureHandling: "none" })

	// Using timeout as it won't work in any other way
	setTimeout(() => {
		// Pan and zoom the map to the borders of Ukraine
		map.panToBounds(ukraineBounds);
		map.fitBounds(ukraineBounds);
	}, 100)

	// Waiting to the end of animation
	setTimeout(() => {
		isMapAnimation = false
		map.setOptions({ gestureHandling: "auto" })
	}, 1000)
}

function expandBounds(bounds, increasePercentage = 1.1) {
	const api = mapRef.value.api

	const pointNorthEast = bounds.getNorthEast();
	const pointSouthWest = bounds.getSouthWest();

	var latAdjustment = (pointNorthEast.lat() - pointSouthWest.lat()) * (increasePercentage - 1);
	var lngAdjustment = (pointNorthEast.lng() - pointSouthWest.lng()) * (increasePercentage - 1);
	
	var newPointNorthEast = new api.LatLng(pointNorthEast.lat() + latAdjustment, pointNorthEast.lng() + lngAdjustment);
	var newPointSouthWest = new api.LatLng(pointSouthWest.lat() - latAdjustment, pointSouthWest.lng() - lngAdjustment);

	let newBounds = new api.LatLngBounds()
	newBounds.extend(newPointNorthEast)
	newBounds.extend(newPointSouthWest)

	return newBounds
}

function panToLocation(location)
{
	mapRef.value.map.panTo(location)
	mapRef.value.map.setZoom(12)
}

defineExpose({ panToLocation });
</script>

<template>
	<GoogleMap 
		ref="mapRef"
		api-key="AIzaSyBgVH0fE8NBe3dgXJzCU9v3be8Q0IcOvQM" 
		style="width: 100%; height: 100%" 
		:disableDefaultUi=true
		:clickableIcons=false
		:isFractionalZoomEnabled=true
		:backgroundColor="'#212121'"
		:minZoom=5
		:styles="darkStyle"
		:center="defaultCenter" 
		:zoom="defaultMapZoom"
	>
		<CustomMarker 
			v-for="station in stationsToShow"
			:key="station.id"
			:options="{ 
				position: { lat: station.latitude, lng: station.longitude }, 
				zIndex: SelectedStation.isSelected(station) ? 10 : station.current_level,
				anchorPoint: 'CENTER'
			}"
			@click="regionsMode ? null : SelectedStation.set(station)"
		>
			<div 
				class="station-marker" 
				v-bind:class="{ 
					mini: regionsMode, 
					selected: SelectedStation.isSelected(station) 
				}"
				:style="{ backgroundColor: uaqiColors[station.current_level] }"
			>
				<FontAwesomeIcon v-if="station.type != 'outdoor' || station.service" :icon="getStationIcon(station)"/>
			</div>
		</CustomMarker>
	</GoogleMap>

	<RegionFloatingBox v-if="selectedRegion != null" :region="selectedRegion"/>
</template>

<style scoped>

.station-marker {
	background-color: gray; 
	
	border-radius: 20px; 
	border: 5px solid #202020;
	
	width: 20px; 
	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	overflow: hidden;

	transition: width 0.3s ease,
				height 0.3s ease,
				border-width 0.3s ease,
				border-color 0.3s ease;
}

.station-marker.selected {
	border-color: #dadada;
}

.station-marker.mini {
	width: 5px !important; 
	height: 5px !important;

	border-width: 0px !important;
}

.station-marker.mini > svg { 
	display: none;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'

import "./assets/main.css"
import "./assets/flexboxgrid.min.css"

import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://reborn.eco-city.org.ua/'

createApp(App)
	.use(router)
	.use(VueGtag, { appName: "RebornOne", config: { id: "G-DLKJT4GH4N" } }, router)
	.mount('#app')

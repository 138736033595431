<template>
	<div class="welcomeContainer">
		<div class="panel">
			<h2>Вітаємо на оновленому EcoCity ✨</h2>

			<p>
				Представляємо вам альфа-версію нашого нового вебсайту. Він все ще 
				перебуває в розробці тому деяких функцій тут може не вистачати.
			</p>
			<p>
				Наразі сайт відображає тільки станції EcoCity з найновішим ПЗ, тому
				ваша улюблена станція поки може не відображатись.
			</p>
			<p>
				Цей вебсайт працює на абсолютно новій серверній інфраструктурі та 
				з спеціально створеним для нього інтерфейсом користувача. Тому 
				ви все ще можете перейти на нашу 
				<a href="https://eco-city.org.ua">класичну версію сайту</a>.
			</p>
			<div style="width:100%; display:flex; justify-content: center;">
				<button 
					class="btn"
					style="
						font-size: medium;
						border: 1px solid #252525;
					"
					@click="emit('closeClicked')"
				>
					Погнали дивитись
					<FontAwesomeIcon :icon="faPlay" style="margin-left: 0.3em;"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

const emit = defineEmits(['closeClicked'])
</script>

<style scoped>
.welcomeContainer {
	position: absolute;
	top: 0px;
	left: 0px; 

	width: 100%;
	height: 100%;

	z-index: 100;

	background-color: #00000060;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 1em;

	box-sizing: border-box;
}

.panel {
	background-color: #000000;
	border-radius: 10px;

	padding: 1.5em;

	max-width: 30em;
}
</style>
<template>
	<NavBar style="flex: 0 0 auto;"/>
	<router-view />
</template>

<script setup>
import NavBar from "@/components/NavBar.vue"
import { onMounted } from "vue";
import { StationsData } from "./state/StationsData";
import { User } from "./state/User";

// Set favicon based on device theme
function setFavicon() {
	const favicon = document.querySelector('link[rel="icon"]')
	favicon.href = (window.matchMedia('(prefers-color-scheme: dark)').matches)
		? '/logo-white.svg'
		: '/logo-black.svg'
}

onMounted(() => {
	console.log("MOUNT APP")

	// Setup station data auto update
	if (StationsData.autoUpdate == false) StationsData.setupAutoUpdating()

	// Login user
	if (User.fetchProfile()) console.log("User login")

	// Set favicon
	setFavicon()
	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setFavicon)
})
</script>

<style>
#app {
	font-family: "Roboto", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #dadada;

	box-sizing: border-box;

	height: 100%;
	max-height: 100vh;

	display: flex;
    flex-direction: column;
}
</style>

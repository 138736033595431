<template>
	<Transition name="appear">
		<div v-if="station.info != null" class="stationInfo">
			<div class="infoContent">
				<div
					style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
					<h2 style="display: flex; align-items: center;">
						<FontAwesomeIcon style="margin-right: 0.35em;" :icon="getStationIcon(station.info)" />
						<span>{{ station.info.name }}</span>
					</h2>
					<button id="close" class="btn" @click="closeOnClick">
						<FontAwesomeIcon :icon="faXmark" />
					</button>
				</div>
				<div class="descriptionContainer">
					<div>
						<FontAwesomeIcon :icon="faLocationCrosshairs"/>
						{{ station.info.address }}
					</div>
					<div>
						<FontAwesomeIcon :icon="faClock"/>
						{{ stationLastOnline }}
					</div>
				</div>
				<br />
				<div class="actions">
					<button class="btn">
						Поширити
						<FontAwesomeIcon :icon="faShare" />
					</button>
					<button class="btn">
						Підписатись
						<FontAwesomeIcon :icon="faBell" />
					</button>
				</div>
				<div>
					<!-- <ShareThis /> -->
				</div>
				
				<div v-show="showData">
					<div class="notePanel" v-if="station.info.type == 'indoor' || station.info.service == true">
						<FontAwesomeIcon :icon="faTriangleExclamation" style="font-size: x-large; padding-right: 0.5em"/>
						<div>
							<div style="font-weight: bold; margin-bottom: 0.3em;">
								Дані можуть бути не точними
							</div>
							<small v-if="station.info.service == true">
								Ця станція потребує обслуговування. 
								Дані можуть не відповідати реальним. <br/>
								<a href="https://eco-city.org.ua/article?id=18">
									Це моя станція, що робити?
								</a>
							</small>
							<small v-if="station.info.type == 'indoor'">
								Ця станція встановлена всередині приміщення.
								Дані можуть не відповідати значенням на вулиці. 
							</small>
						</div>
					</div>

					<h3>Діаграма UAQI</h3>
					<small>
						Діаграма показує забруднення по окремих категоріях забрудників.
						<a href="https://eco-city.org.ua/article?id=5" target="_blank">Що означає цей рівень?</a>
					</small>
					<br /><br />
					<div>
						<UaqiDiagram :uaqi=uaqi />
					</div>
					<br />

					<h3>Забрудники</h3>
					<small>
						Тут представлений список всіх показників, що виміряються цією станцією.
						<a href="https://eco-city.org.ua/article?id=5" target="_blank">Що означають ці кольори?</a>
					</small>
					<br /><br />
					<div class="stationValuesList">
						<TransitionGroup>
							<StationValue v-for="v in station.data?.current_values" :key="v" :value="v"></StationValue>
						</TransitionGroup>
					</div>
				</div>
				<div v-show="!showData">
					<div class="notePanel">
						<FontAwesomeIcon :icon="faTriangleExclamation" style="font-size: x-large; padding-right: 0.5em"/>
						<div>
							<div style="font-weight: bold; margin-bottom: 0.3em;">
								Дані застарілі
							</div>
							<small style="margin-bottom: 0.5em;">
								Востаннє ця станція надсилала дані більш ніж годину тому. 
								<a 
									@click="showOldData = true"
									style="text-decoration: underline; cursor: pointer;"
								>
									Показати?
								</a>
							</small>
						</div>
					</div>
				</div>
				<br />

				<h3>Треба більше даних?</h3>
				<br />
				<div 
					class="statsBtn"
					@click="statsOnClick"
				>
					<div>
						<h3>Переглянути статистику</h3>
						<small>
							Дані за тиждень, місяць та навіть роки! 
							А також інструменти для порівняння та аналізу даних.
						</small>
					</div>
				</div>
				<br />
				<h3>Джерело даних</h3>
				<br />
				<div 
					class="ownerBox" 
					v-if="station.data?.owner != null && station.data?.owner?.logo != ''"
					style="margin-bottom: 0.5em;"
				>
					<div v-if="station.data.owner.logo != ''" class="logoContainer">
						<img 
							:src="'https://reborn.eco-city.org.ua/logos/' + station.data.owner.logo" 
							alt="user logo" 
							style="width: 100%; height: 100%;"
						>
					</div>
					<div style="margin-left: 0.75em">
						<div>
							<h4>
								<span v-if="station.data?.owner?.url == ''">
									{{ station.data?.owner?.name }}
								</span>
								<a v-else :href="station.data?.owner?.url" target="_blank" rel="noopener noreferrer">
									{{ station.data?.owner?.name }}
								</a>
							</h4>
							<small>Власник станції</small>
						</div>
					</div>
				</div>

				<div class="providerBox">
					<div class="logoContainer" style="width: 2em; height: 2em; padding: 0.25em">
						<img src="@/assets/logo-white.svg" alt="user logo" style="width: 100%; height: 100%;">
					</div>
					<div style="margin-left: 0.5em">
						Мережа {{ providerToText[station.data?.provider] }}
					</div>
				</div>
				<br /><br />
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
	faTriangleExclamation, 
	faXmark, 
	faBell, 
	faShare,
	faLocationCrosshairs 
} from '@fortawesome/free-solid-svg-icons'

import { faClock } from '@fortawesome/free-regular-svg-icons'

import StationValue from '@/components/StationValue.vue'
import UaqiDiagram from './UaqiDiagram.vue'
// import ShareThis from './ShareThis.vue'

import { SelectedStation } from '@/state/SelectedStation.js'
import { getStationIcon } from '@/modules/StationIcon.js'

import { differenceInMinutes } from 'date-fns';

const router = useRouter()

const providerToText = {
	"ecocity": "EcoCity",
	"lun": "Lun Місто Air"
}

function statsOnClick() 
{
	router.push(
		{ 
			name: 'station', 
			params: { id: station.value.info.id } 
		}
	)
}

function closeOnClick() { 
	SelectedStation.remove()
	clearInterval(updateDataInterval.value) 
}

const stationLastOnline = computed(()  => {
	if (station.value.data == null || station.value.data.id != station.value.info.id) {
		return station.value.info.last_online
	} else {
		return station.value.data.last_online
	}
})

const dataIsTooOld = computed(() => {
	return differenceInMinutes(new Date(), new Date(stationLastOnline.value)) > 60
})

const showOldData = ref(false)

const showData = computed(() => { return dataIsTooOld.value == false || showOldData.value == true })

SelectedStation.onStationChanged(function () {
	showOldData.value = false
})

const station = computed(() => {
	return {
		info: SelectedStation.getInfo(),
		data: SelectedStation.getData()
	}
})

const uaqi = computed(() => {
	const uaqi = station.value?.data?.uaqi

	if (uaqi == null) return { 
		main: 0,
		pm: 0,
		mainGases: 0,
		otherGases: 0,
		radiation: 0
	};

	return {
		main: uaqi.main,
		pm: uaqi.categories.A,
		mainGases: uaqi.categories.B,
		otherGases: uaqi.categories.C,
		radiation: uaqi.categories.D
	}
})

const updateDataInterval = ref(null)

onMounted(() => {
	updateDataInterval.value = setInterval(() => {
		if (SelectedStation.station != null) {
			SelectedStation.fetchData()
			SelectedStation.fetchGraph()
		}
	}, 5000)
})
</script>

<style scoped>

.notePanel {
	padding: 1em;
	border-radius: 10px;
	background-color: #202020;

	display: flex;
	flex-direction: row;
	align-items: center;

	margin-bottom: 1.2em;
}

.descriptionContainer svg {
	width: 1em;
}

.descriptionContainer > div {
	white-space: nowrap;
	overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
	margin-bottom: 1.2em;
	display: flex;
}

.actions > .btn {
	margin-right: 0.5em;
}

/* 
.actions > .btn {
	width: 100%;
}

.actions > .btn + .btn {
	margin-left: 0.5em;
} */

.statsBtn {
	width: 100%;
	aspect-ratio: 2 / 1;

	border-radius: 10px;

	overflow: hidden;

	background: linear-gradient(to top, #202020 0%, #202020ee 40%, #00000040 100%), 
				url(https://media.istockphoto.com/id/1407983911/photo/forex-diagrams-and-stock-market-rising-lines-with-numbers.jpg?s=612x612&w=0&k=20&c=zas1h6LR6v2iCvE7SWnVoZ_s7ZSiboN45UK0d5oMWac=);

	background-size: cover;
	background-position: center;

	display: flex;
	align-items: end;

	border: 1px solid #303030;

	cursor: pointer;
	user-select: none;

	transition: all 0.3s ease;
}

.statsBtn > div {
	padding: 1em;
}

.statsBtn:hover {
	box-shadow: 0 2px 10px #202020aa;
	translate: 0 -2px;
}

.stationValuesList > * {
	margin-bottom: 0.5em;
}

h3 {
	margin-top: 0.5em;
	margin-bottom: 0.2em;
}

small {
	color: #aeaeae;
}

#close {
	border-radius: 15px;
	width: 2.5em;
	height: 2.5em;
}

.stationInfo {
	box-sizing: border-box;

	flex-shrink: 0;

	height: 100%;
	max-height: 100%;

	width: 100%;

	background-color: #101010;

	overflow: hidden;

	z-index: 20;
}

.infoContent {
	box-sizing: border-box;
	width: 100%;
	height: 100%; 

	/* For proper animations */
	min-width: 400px;

	overflow-y: scroll;
	overflow-x: hidden;

	padding: 20px;
}

@media only screen and (min-width: 700px) {
	.stationInfo {
		width: 400px;
	}
}

@media only screen and (max-width: 700px) {
	.infoContent {
		min-width: 100vw;
	}
}

.logoContainer {
	width: 4em;
	height: 4em;
	aspect-ratio: 1 / 1; 
	border-radius: 5px; 
	overflow: hidden; 

	flex-shrink: 0;

	background-color: #101010;
}

.ownerBox {
	background-color: #202020;
	padding: 0.5em;
	border-radius: 10px;

	border: 1px solid #303030;

	display: flex;
	flex-direction: row;
}

.ownerBox > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.ownerBox h4 {
	margin-top: 0.1em;
	margin-bottom: 0.15em;
}

.ownerBox a {
	color: var(--color-text)
}

.providerBox {
	background-color: #202020;
	padding: 0.5em;
	border-radius: 10px;

	border: 1px solid #303030;

	display: flex;
	flex-direction: row;
	align-items: center;

	user-select: none;
}

.appear-enter-active,
.appear-leave-active {
	transition: max-width 1s ease;
	max-width: 100%;
}

.appear-enter-from,
.appear-leave-to {
	max-width: 0em;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease-out,
				filter 0.5s ease-out,
				padding 0.3s ease-in-out,
				margin 0.3s ease-in-out,
				max-height 0.3s ease-in-out,
				border-width 0.3s ease-in-out;
	overflow: hidden;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	filter: blur(6px);

	max-height: 0;
	overflow: hidden;
	
	padding: 0px !important;
	margin: 0px !important;
	border-width: 0;
}
</style>
<script>
export default { name: 'NavBar' }
</script>

<template>
	<nav>
		<div class="navMenu">
			<img id="logo" src="@/assets/logo-white.svg">
			<router-link to="/">Мапа</router-link> 
			<router-link to="/stats">Статистика</router-link> 
			<router-link to="/about">Про нас</router-link>
		</div>
		<div class="navMenu">
			<router-link 
				v-if="User.profile == null" 
				to="/login" 
				id="loginButton"
			>
				<span class="loginButtonText">Кабінет</span>
				<FontAwesomeIcon :icon="faUser"/>
			</router-link> 
			<router-link 
				v-else
				to="/cabinet"
				id="loginButton"
			>
				<div v-if="User.profile?.logo == null">
					<span id="loginButtonText">Кабінет</span>
					<FontAwesomeIcon :icon="faUser"/>
				</div>
				<div v-else class="accountContainer">
					<span class="loginButtonText">Кабінет</span>
					<div class="logoContainer">
						<img 
							:src="'https://reborn.eco-city.org.ua/logos/' + User.profile?.logo" 
							alt="user logo" 
							style="width: 100%; height: 100%;"
						>
					</div>
				</div>
			</router-link> 
		</div>
	</nav>
</template>

<script setup>
import { watch  } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { User } from '@/state/User.js'

watch(User, async () => {
	if (User.isAuthenticated()) {
		if (User.profile == null) await User.fetchProfile()
	}
})

</script>

<style scoped>
#logo {
	height: 2em;
	filter: drop-shadow(0px 2px 10px #dadada60);
}

.accountContainer {
	display: flex;

	flex-direction: row;
}

.logoContainer {
	width: 1.4em;
	height: 1.4em;
	aspect-ratio: 1 / 1; 
	border-radius: 100%; 
	overflow: hidden; 

	flex-shrink: 0;

	background-color: #101010;

	margin-left: 0.3em;
	margin-top: -0.2em;
}

@media only screen and (max-width: 520px) {
	.loginButtonText {
		display: none;
	}

	.logoContainer {
		margin-left: 0em;
	}
}

nav {
	padding: 12px 25px;
	display: flex;
	justify-content: space-between;

	background-color: #101010;

	box-shadow: 0px 2px 58px 0px #202020;

	user-select: none;

	z-index: 100;
}

.navMenu {
	display: flex;
}

nav a {
	color: #aaa;
	
	font-family: "Montserrat", sans-serif;
	font-weight: bolder;
	font-size: large;

	text-decoration: none;

	padding: 0.2em;
	margin: 0.1em;
	margin-top: 0.3em;

	transition: all 0.3s ease-in-out;
}

nav a:hover {
	translate: 0px -2px;
	filter: drop-shadow(0px 4px 10px var(--color-good));
}

nav a.router-link-exact-active {
	color: white;
	filter: drop-shadow(0px 2px 10px #dadada60);
	transition: all 0.3s ease-in-out;
}

nav a.router-link-exact-active:hover {
	filter: drop-shadow(0px 4px 10px var(--color-text));
}
</style>
<template>
	<div class="calendarContainer" ref="calendarContainer">
		<table class="table">
			<thead class="tableHeader">
				<th
					v-for="e in headerRow"
					:key="e"
				>
					<span>{{ e }}</span>
				</th>
			</thead>
			<tr 
				v-for="weekDays in dataTable"
				:key=weekDays
				class="tableRow"
			>
				<td 
					v-for="d in weekDays"
					:key=d
					:class="{ rowHeader: d.rowName != null }"
				>
					<span v-if="d.rowName != null">
						{{ d.rowName }}
					</span>
					<div 
						v-else-if="d.value != null" 
						:style="{ backgroundColor: uaqiCalendarColors[d.value] }"
					>
						<!-- {{ d.value }} -->
						<!-- <span style="font-size: xx-small;">{{ d.date.slice(5) }}</span> -->
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script setup>

import { ref, onMounted } from 'vue'

import { uaqiColors } from '@/modules/UAQI.js'

const uaqiCalendarColors = [ "#151515" ].concat(uaqiColors.slice(1))

let data = {
	"2024-03-01": 4,
	"2024-03-02": 1,
	"2024-03-03": 3,
	"2024-03-04": 6,
	"2024-03-05": 2,
	"2024-03-06": 0,
	"2024-03-07": 5,
	"2024-03-08": 1,
	"2024-03-09": 3,
	"2024-03-10": 4,
	"2024-03-11": 0,
	"2024-03-12": 2,
	"2024-03-13": 6,
	"2024-03-14": 1,
	"2024-03-15": 5,
	"2024-03-16": 3,
	"2024-03-17": 0,
	"2024-03-18": 4,
	"2024-03-19": 6,
	"2024-03-20": 2,
	"2024-03-21": 1,
	"2024-03-22": 3,
	"2024-03-23": 5,
	"2024-03-24": 0,
	"2024-03-25": 6,
	"2024-03-26": 4,
	"2024-03-27": 1,
	"2024-03-28": 3,
	"2024-03-29": 2,
	"2024-03-30": 5,
	"2024-03-31": 6,
	"2024-04-01": 0,
	"2024-04-02": 4,
	"2024-04-03": 2,
	"2024-04-04": 3,
	"2024-04-05": 6,
	"2024-04-06": 1,
	"2024-04-07": 5,
	"2024-04-08": 0,
	"2024-04-09": 2,
	"2024-04-10": 4,
	"2024-04-11": 3,
	"2024-04-12": 6,
	"2024-04-13": 1,
	"2024-04-14": 5,
	"2024-04-15": 0,
	"2024-04-16": 2,
	"2024-04-17": 4,
	"2024-04-18": 6,
	"2024-04-19": 3,
	"2024-04-20": 1,
	"2024-04-21": 5,
	"2024-04-22": 0,
	"2024-04-23": 6,
	"2024-04-24": 3,
	"2024-04-25": 4,
	"2024-04-26": 1,
	"2024-04-27": 2,
	"2024-04-28": 5,
	"2024-04-29": 6,
	"2024-04-30": 0,
	"2024-05-01": 2
};

function sortData(data) {
	let entries = Object.entries(data)
	entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

	let sortedData = {}
	for (let [key, value] of entries) sortedData[key] = value

	return sortedData
}

function adjustDataToStartFromMonday(data) {
	const dates = Object.keys(data).map(date => new Date(date));
	const firstDate = dates[0];
	const firstDayOfWeek = ((firstDate.getDay() + 6) % 7);

	const result = {};

	for (let i = firstDayOfWeek; i > 0; i--) {
		const newDate = new Date(firstDate);
		newDate.setDate(newDate.getDate() - i);
		const formattedDate = newDate.toISOString().split('T')[0];
		result[formattedDate] = null;
	}

	Object.keys(data).forEach(date => {
		result[date] = data[date];
	});

	return result;
}

function adjustDataToHave365days(data) {
	const dates = Object.keys(data).map(date => new Date(date))
	const firstDate = dates[0]

	let startDate = new Date(firstDate)
	startDate.setDate(startDate.getDate() - (365 - dates.length))

	// Result object
	const result = {};

	// Date iterator
	let currentDate = new Date(startDate);
	currentDate.setHours(12, 0, 0)

	while (currentDate <= firstDate) {
		const formattedDate = currentDate.toISOString().split('T')[0];
		result[formattedDate] = 0;

		// Increment date
		currentDate.setDate(currentDate.getDate() + 1);
		currentDate.setHours(12, 0, 0)
	}

	// Create result object
	Object.keys(data).forEach(date => {
		result[date] = data[date];
	});

	return result;
}

function generateCalendarData(data) {

	const dayNames = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"];
	
	const dataTable = dayNames.map(day => [{ "rowName": day.toLowerCase() }]);
	
	for (const [date, value] of Object.entries(data)) {
		const dayIndex = ((new Date(date).getDay() + 6) % 7);
		dataTable[dayIndex].push({ "value": value, "date": date });
	}

	return dataTable;
}

function generateHeaderForDataTable(dataTable) {
	const firstRow = dataTable[0]

	let headerRow = [ ]

	const monthNames = ["Січ", "Лют", "Бер", "Квіт", "Трав", "Черв",
		"Лип", "Серп", "Вер", "Жовт", "Лист", "Груд"
	];

	firstRow.forEach(e => {
		if (e.date == null) {
			headerRow.push("")
			return
		}

		const month = monthNames[new Date(e.date).getMonth()]

		if (headerRow.find(e => e == month) == null) {
			headerRow.push(month)
		} else {
			headerRow.push("")
		}
	})

	return headerRow
}

data = sortData(data)
data = adjustDataToHave365days(data)
data = sortData(data)
data = adjustDataToStartFromMonday(data)

const dataTable = generateCalendarData(data)

const headerRow = generateHeaderForDataTable(dataTable)

// To scroll to end by default 
const calendarContainer = ref(null);

const scrollToEnd = () => {
	const container = calendarContainer.value;
	container.scrollLeft = container.scrollWidth;
};

onMounted(() => {
	scrollToEnd()
})
</script>

<style scoped>
.calendarContainer {
	/* width: 100%; */

	user-select: none;

	overflow-x: auto;

	box-sizing: border-box;
}

.tableHeader > th {
	overflow: visible;
	max-width: 1.2em;
	width: 1.2em;
	text-align: left;
}

.tableHeader > th > span {
	font-size: small;
}

.rowHeader {
	position: absolute;

	font-size: small;
	padding: 0;
}

.table {
	font-size: 10.5px;
}

.tableRow > td > div {
	width: 1.2em;
	height: 1.2em;

	background-color: gray;

	border-radius: 3px;
}

table, th, td {
	border: 0px solid white;
	border-collapse: collapse;
}

td {
	padding: 2px;
}
</style>
<template>
	<div class="contentContainer">
		<div class="content">
			<h1>Вхід до кабінету</h1> 
			<p>Кабінет дозволяє вам керувати власними станціями моніторингу</p>

			<div class="noteBox">
				<FontAwesomeIcon :icon="faTriangleExclamation" style="font-size: x-large; padding-right: 0.5em"/>
				<div>
					<div style="margin-bottom: 0.3em; font-weight: bold;">
						Зареєстровані користувачі
					</div>
					<small>
						Якщо ви були зареєстровані на сайті старої версії, вам
						треба <router-link to="/password-recovery">відновити пароль</router-link>
						перед тим, як входити в акаунт.
					</small>
				</div>
			</div><br/>

			<small v-show="showInvalid" style="color: var(--color-bad)">Перевірте правильність введених даних</small>
			
			<input 
				v-model="email" 
				type="text" 
				placeholder="Введіть ваш e-mail" 
				class="text-box"
				:class="{ invalid: !isEmailValid(email) && showInvalid }"
				@keyup.enter="passwordInput.focus()"
			>
			<input 
				v-model="password" 
				type="password" 
				placeholder="Введіть ваш пароль" 
				class="text-box"
				ref="passwordInput"
				@keyup.enter="enterButton.click()"
			>
			
			<div style="padding-top: 0.5em;">
				<button 
					class="btn" 
					@click="enterButtonClick()"
					ref="enterButton"
				>
					Вхід
					<FontAwesomeIcon :icon="faRightToBracket"/>
				</button>
			</div>

			<div style="display: flex; justify-content: center;">
				<p>
					<router-link to="/register">Зареєструватись</router-link> / 
					<router-link to="/password-recovery">Відновити пароль</router-link>
				</p>
				<br>
			</div>

			<div class="loginProviders" style="display: flex; justify-content: center;">
				<button class="btn">
					<FontAwesomeIcon :icon="faGoogle"/>
					Увійти через Google
				</button>
			</div>

			<br/>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import { User } from '@/state/User.js'
import { isEmailValid } from '@/modules/DataValidation'

const router = useRouter()

const passwordInput = ref(null)
const enterButton = ref(null)

const email = ref("")
const password = ref("")

const showInvalid = ref(false)

async function enterButtonClick() {
	if (!isEmailValid(email.value)) {
		showInvalid.value = true
		return
	}

	console.log("LOGIN RUN")

	if (await User.login(email.value, password.value)) {
		router.push({ name: 'cabinet' })
	} else {
		window.alert("Не вдалось увійти. Можливо ви ввели неправльний логін і пароль.")
	}
}

watch(User, () => {
	if (User.isAuthenticated()) {
		router.push({ name: 'cabinet' })
	}
})

watch(email, () => {
	if (showInvalid.value && isEmailValid(email.value)) showInvalid.value = false
})
</script>

<style scoped>
.content {
	max-width: 24em;
}

.btn, .text-box {
	font-size: medium;
	/* border-radius: 8px; */
}

.text-box.invalid {
	background-color: #7c2f2f;
}

a { 
	color: var(--color-good);
}

.noteBox {
	border-radius: 0.3em;
	background-color: #202530;

	padding: 1em;

	display: flex;
	align-items: center;
}
</style>
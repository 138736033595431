<template>
	<div class="searchContainer">
		<div class="searchOnMap">
			<FontAwesomeIcon :icon="faSearch" style="margin-right: 0.5em;" />
			<input 
				type="text" 
				placeholder="Пошук на мапі..." 
				v-model="searchText" 
				@keydown="inputKeydown"
				@input="selectedIndex = 0"
			>
		</div>
		<div v-if="searchResults.length > 0" class="searchResults">
			<div 
				v-for="(r, index) in searchResults" 
				:key="r.item.name" 
				class="searchResult"
				:class="{ active: index == selectedIndex }" 
				@click="onResultClick(r.item)"
			>
				<FontAwesomeIcon 
					:icon="r.item.icon" 
					style="font-size: medium; margin-right: 0.8em"
				/>
				<div>
					<div>{{ r.item.name }}</div>
					<small 
						v-if="r.item.description != '-'" 
						style="color: gray; white-space: nowrap"
					>
						{{ r.item.description }}
					</small>
				</div>
			</div>
		</div>
		<div v-else-if="searchText.length > 0" class="searchResults" style="padding: 10px 20px;">
			<span style="color: gray">На жаль, ми нічого не знайшли...</span>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from 'vue'
import { useFuse } from '@vueuse/integrations/useFuse'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'

import { StationsData } from '@/state/StationsData.js'
import { SelectedStation } from '@/state/SelectedStation'
import { getStationIcon } from '@/modules/StationIcon.js'

import axios from 'axios'
import { event } from 'vue-gtag'

const emit = defineEmits(['resultClicked'])

function onResultClick(item) {
	emit('resultClicked', item.location)

	// Invoke Google Analytics event
	event('search', { search_term: searchText.value })

	if (item.station) {
		// Time for map to zoom
		setTimeout(() => {
			SelectedStation.set(item.station)
		}, 500);
	}

	searchText.value = ''
}

function transliterateToUkr(text) {
    // Table of transliteration
    const transliterationMap = {
        'a': 'а', 'b': 'б', 'c': 'ц', 'd': 'д', 'e': 'е', 'f': 'ф', 'g': 'г',
        'h': 'х', 'i': 'і', 'j': 'й', 'k': 'к', 'l': 'л', 'm': 'м', 'n': 'н',
        'o': 'о', 'p': 'п', 'q': 'к', 'r': 'р', 's': 'с', 't': 'т', 'u': 'у',
        'v': 'в', 'w': 'в', 'x': 'кс', 'y': 'и', 'z': 'з',
        'A': 'А', 'B': 'Б', 'C': 'Ц', 'D': 'Д', 'E': 'Е', 'F': 'Ф', 'G': 'Г',
        'H': 'Х', 'I': 'І', 'J': 'Й', 'K': 'К', 'L': 'Л', 'M': 'М', 'N': 'Н',
        'O': 'О', 'P': 'П', 'Q': 'К', 'R': 'Р', 'S': 'С', 'T': 'Т', 'U': 'У',
        'V': 'В', 'W': 'В', 'X': 'Кс', 'Y': 'И', 'Z': 'З'
    };

    let transliteratedText = '';
    for (let char of text) {
        transliteratedText += transliterationMap[char] || char;
    }

    return transliteratedText;
}

const fetchSettlements = async () => {
	try {
		const response = await axios.get('ukraine_settlements.json')
		console.log(response.data)

		settlements.value = response.data
	} catch (error) {
		console.error('Error fetching settlements:', error)
	}
}

const settlements = ref([])

onMounted(() => {
	fetchSettlements()
})

const searchIndex = computed(() => {
	let result = []

	if (StationsData.stations) {
		StationsData.stations.forEach(s => {
			result.push({
				name: s.name,
				alternative: transliterateToUkr(s.name),
				description: s.address,
				location: { 
					lat: parseFloat(s.latitude),
					lng: parseFloat(s.longitude)
				},
				type: "station",
				icon: getStationIcon(s),
				station: s
			})
		})
	}

	if (settlements.value) {
		settlements.value.forEach(s => {
			result.push({
				name: s.name,
				description: s.region,
				location: s.location,
				type: "region",
				icon: faMapLocationDot
			})
		})
	}

	return result
})

const searchText = ref('')

const fuseOptions = {
	// isCaseSensitive: false,
	// includeScore: false,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	// minMatchCharLength: 1,
	// location: 0,
	// threshold: 0.6,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	// fieldNormWeight: 1,
	keys: [
		{ name: "name", weight: 3 },
		{ name: "alternative", weight: 1 },
	]
};

const { results: searchResults } = useFuse(searchText, searchIndex, { fuseOptions: fuseOptions, resultLimit: 10 })

const selectedIndex = ref(0)

function inputKeydown(e) {
	if (e.key == "ArrowUp" && selectedIndex.value > 0) {
		selectedIndex.value--
	}
	if (e.key == "ArrowDown" && selectedIndex.value < (searchResults.value.length - 1)) {
		selectedIndex.value++
	}

	// If arrow keys
	if (e.key == "ArrowUp" || e.key == "ArrowDown") {
		// Prevent default keystrokes
		e.preventDefault()

		// Scroll to selected item
		scrollToSelected()
	}

	if (e.key == "Enter") {
		onResultClick(searchResults.value[selectedIndex.value].item)
	}

	if (e.key == "Escape") {
		searchText.value = ""
	}
}

const scrollToSelected = () => {
	// Get all search result elements
	const resultElements = document.querySelectorAll('.searchResult');

	if (resultElements[selectedIndex.value]) 
	{
		resultElements[selectedIndex.value].scrollIntoView({
			behavior: 'smooth',
			block: 'nearest'
		});
	}
};
</script>

<style scoped>
.searchContainer {
	width: 100%;
	max-width: 25em;
	position: relative;

	z-index: 10;
}

.searchOnMap {
	background-color: #101010;
	border-radius: 15px;
	padding: 10px 15px;
	width: 100%;

	box-sizing: border-box;

	font-size: medium;

	display: flex;
	align-items: center;

	color: #dadada
}

.searchResults {
	background-color: #101010;
	border-radius: 15px;

	margin-top: 10px;
	padding: 5px;

	position: absolute;

	box-sizing: border-box;

	width: 100%;

	max-height: 50vh;
	overflow-y: auto;

	box-shadow: 0px 2px 40px 0px #000000;
}

.searchResult {
	background-color: #101010;
	padding: 10px 15px;

	border-radius: 10px;

	font-size: medium;

	display: flex;
	align-items: center;
}

.searchResult:hover {
	background-color: #202020;
}

.searchResult.active {
	background-color: #202020;
}

input {
	background-color: #101010;
	outline: none;
	border: none;

	width: 100%;

	font-size: inherit;

	color: inherit;
}

input:active {
	outline: none;
	border: none;
}
</style>
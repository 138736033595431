<template>
	<div 
		v-if="position != null"
		class="regionBox" 
		:style="{ top: `${position.y - 60}px`, left: `${position.x + 20}px` }"
	>
		<div 
			style="font-weight: bold;" 
			:style="{ color: uaqiColorsBright[region.level]}"
		>
			{{ region.name }}
		</div>
		<div>{{ uaqiDescriptions[region.level].name }}</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps } from 'vue';
import { uaqiColorsBright, uaqiDescriptions } from '@/modules/UAQI.js'

defineProps({
	region: Object
})

// Mouse position
const position = ref(null)

// Mousemove event listener have to be saved to delete on unmount
var listener = null;

onMounted(() => {
	// Update cursor position for region info box
	listener = window.addEventListener('mousemove', () => {
		position.value = { x: event.clientX, y: event.clientY }
	})
})

onBeforeUnmount(() => {
	window.removeEventListener('mousemove', listener)
})
</script>

<style scoped>
.regionBox {
	position: absolute;
	
	background-color: #202020;
	border-radius: 10px;
	padding: 5px;

	/* This ensures the element doesn't interfere with mouse events */
	pointer-events: none;

	/* Add transition for smooth movement */
	transition: top 0.2s ease, left 0.2s ease;
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import StatsView from '@/views/StatsView.vue'
import RegionView from '@/views/RegionView.vue'
import LoginView from '@/views/LoginView.vue'
import CabinetView from '@/views/CabinetView.vue'
import StationView from '@/views/StationView.vue'
import PasswordRecoveryView from '@/views/PasswordRecoveryView.vue'
import PasswordResetView from '@/views/PasswordResetView.vue'
import AddStationView from '@/views/AddStationView.vue'
import CabinetStationView from '@/views/CabinetStationView.vue'

import { User } from '@/state/User.js'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/stats',
		name: 'stats',
		component: StatsView
	},
	{
		path: '/region/:id',
		name: 'region',
		component: RegionView,
		props: true,
		meta: { scrollToTop: true }
	},
	{
		path: '/station/:id',
		name: 'station',
		component: StationView,
		props: true,
		meta: { scrollToTop: true }
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
		beforeEnter: (to, from, next) => {
			if (User.isAuthenticated()) {
				next('/cabinet')
				return
			} else {
				next()
			}
		}
	},
	{
		path: '/password-recovery',
		name: 'password-recovery',
		component: PasswordRecoveryView,
		beforeEnter: (to, from, next) => {
			if (User.isAuthenticated()) {
				next('/cabinet')
				return
			} else {
				next()
			}
		}
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: PasswordResetView
	},
	{
		path: '/cabinet',
		name: 'cabinet',
		component: CabinetView,
		meta: { requiresAuth: true, scrollToTop: true }
	},
	{
		path: '/cabinet/add-station',
		name: 'add-station',
		component: AddStationView,
		meta: { requiresAuth: true }
	},
	{
		path: '/cabinet/station/:id',
		name: 'cabinet-station',
		props: true,
		component: CabinetStationView,
		meta: { requiresAuth: true, scrollToTop: true }
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// If the route has a scrollToTop parameter, scroll to top
		if (to.meta.scrollToTop) return { top: 0 }

		// We restore the preserved position if it exists
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0 }
		}
	}
})

router.beforeEach((to, _, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (User.isAuthenticated()) {
			next();
			return;
		}
		next('/login');
	} else {
		next();
	}
});

export default router
